import { ReactNode } from "react";

type Level = 1 | 2 | 3 | 4;
type HeadingTag = "h1" | "h2" | "h3" | "h4";
type TextSize = "xs" | "sm" | "base" | "lg" | "xl" | "2xl" | "3xl" | "4xl";
type Size = `text-${TextSize}`;
export const Headline = ({
  children,
  level = 2,
  className = "",
  size,
  weight = "bold",
}: {
  level?: Level;
  children: ReactNode;
  className?: string;
  size?: Size;
  weight?: "medium" | "semibold" | "bold";
}) => {
  const Comp = `h${level}` as HeadingTag;
  const calculatedSize =
    size ?? level === 1 ? "text-3xl" : level === 2 ? "text-2xl" : "text-xl";
  const calculatedLgSize =
    size ?? level === 1
      ? "lg:text-4xl"
      : level === 2
      ? "lg:text-3xl"
      : "lg:text-2xl";
  let calculatedWeight = `font-${weight}`;
  if (level === 2) {
    calculatedWeight = "font-medium";
  }
  if (level === 3) {
    calculatedWeight = "font-normal";
  }
  return (
    <Comp
      className={`mb-4 font-antonio ${calculatedSize} ${calculatedLgSize} ${calculatedWeight} ${
        level <= 1
          ? "lg:bg-clip-text lg:bg-gradient-to-t lg:from-black lg:to-blue lg:text-transparent"
          : ""
      } ${className}`}
    >
      {children}
    </Comp>
  );
};
