import Link from "next/link";
import { useRouter } from "next/router";

interface LanguageSwitchProps {
  type?: "big" | "small";
}
export function LanguageSwitch({ type = "big" }: LanguageSwitchProps) {
  const { asPath, locale } = useRouter();
  return (
    <Link
      href={asPath}
      locale={locale === "de" ? "en" : "de"}
      className="flex gap-2"
    >
      <div className={`${locale === "de" ? "underline" : ""}`}>
        {type === "big" ? "Deutsch" : "DE"}
      </div>
      <div>/</div>
      <div className={`${locale === "en" ? "underline" : ""}`}>
        {type === "big" ? "English" : "EN"}
      </div>
    </Link>
  );
}
