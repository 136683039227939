import { DropdownMenuTrigger } from "@radix-ui/react-dropdown-menu";
import { Tag } from "@tryghost/content-api";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { FiMoreHorizontal } from "react-icons/fi";
import { WithClassName, splitArray, useIsScrolled } from "../../helper/util";
import { News } from "../../models/types";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
} from "./Dropdown";
import { DynLink } from "./DynLink";
import { Headline } from "./Headline";
import { MobileNav } from "./MobileNav";

function getCurrentSection(pathname: string) {
  return pathname.split("/")[1] ?? "";
}

interface NavProps extends React.PropsWithChildren {
  newsTags?: Tag[];
  article?: News;
  endpointSelectionString?: string;
}
export function Nav({
  newsTags,
  article,
  endpointSelectionString,
  children,
}: NavProps) {
  return (
    <>
      <MobileNav />
      <DesktopNav
        newsTags={newsTags}
        article={article}
        endpointSelectionString={endpointSelectionString}
      >
        {children}
      </DesktopNav>
    </>
  );
}

interface DesktopNavProps extends React.PropsWithChildren {
  newsTags?: Tag[];
  article?: News;
  endpointSelectionString?: string;
}
function DesktopNav({
  newsTags,
  article,
  endpointSelectionString,
  children,
}: DesktopNavProps) {
  const { pathname } = useRouter();
  const { t } = useTranslation();

  return (
    <>
      <div
        className={`bg-white pt-20 flex  pb-8 top-0 w-full z-10 max-lg:hidden `}
      >
        <div className="container flex gap-8">
          <Link href="/">
            <Image
              src="/TTBL_Logo.svg"
              alt="Logo TTBL"
              className="w-auto h-14"
              width={500}
              height={600}
            />
          </Link>
          <div className="flex gap-4 text-2xl items-end">
            <NavLink href="/">{t("Nav_Home")}</NavLink>
            <NavLink href="/news/all/1">{t("Nav_News")}</NavLink>
            <NavLink href="/bundesliga/gameschedule/current/current/all">
              {t("Nav_Bundesliga")}
            </NavLink>
            <NavLink href="/pokal/gameschedule/current/current/all">
              {t("Nav_Pokal")}
            </NavLink>
            <NavLink href="/about/ttblsportgmbh">{t("Title_The_TTBL")}</NavLink>
          </div>
          <div className="flex ml-auto items-end">
            <DynLink href="https://www.dyn.sport/competition/79806" />
          </div>
        </div>
      </div>
      <Subnav section={getCurrentSection(pathname)} newsTags={newsTags} />
      {getCurrentSection(pathname) !== "" && (
        <BreadCrumbs
          newsTags={newsTags}
          article={article}
          endpointSelectionString={endpointSelectionString}
        />
      )}
      {getCurrentSection(pathname) !== "" && (
        <PageHeader
          newsTags={newsTags}
          article={article}
          endpointSelectionString={endpointSelectionString}
        >
          {children}
        </PageHeader>
      )}
    </>
  );
}

interface SubnavLink {
  title: string;
  href: string;
}

interface SubnavProps {
  section: string;
  newsTags?: Tag[];
}

function Subnav({ section }: SubnavProps) {
  const { t } = useTranslation();
  const links: SubnavLink[] = [];
  let title: string | undefined = undefined;
  let replace = false;
  switch (section) {
    case "":
    case "contestconditions":
    case "privacy":
    case "imprint":
    case "404":
    case "500":
    case "tickets":
    case "newsletter":
    case "quiz":
      replace = true;
      links.push({
        title: t("Title_Gameschedule"),
        href: "/bundesliga/gameschedule/current/current/all",
      });
      links.push({ title: t("Title_Table"), href: "/bundesliga/table" });
      links.push({
        title: t("Title_Rankings"),
        href: "/bundesliga/ranking/all/single",
      });
      links.push({ title: t("Title_Tickets"), href: "/tickets" });
      links.push({ title: t("Title_Newsletter"), href: "/newsletter" });
      links.push({ title: "Quiz", href: "/quiz" });
      // links.push({ title: t("Title_Livestreams"), href: "/livestreams" });
      title = t("Title_Table_Tennis_Bundesliga") as string;
      break;
    case "news":
      // newsTags &&
      //   links.push(
      //     ...newsTags.map((tag) => ({
      //       title: tag.name ?? "Title",
      //       href: `/${tag.slug}`,
      //     }))
      //   );
      links.push({ title: "Bundesliga", href: "/bundesliga/1" });
      links.push({ title: "Pokal", href: "/pokal/1" });
      links.push({ title: "Transfer", href: "/transfer/1" });
      links.push({ title: t("Verschiedenes"), href: "/verschiedenes/1" });
      links.push({ title: "International", href: "/international/1" });
      title = t("Title_News") as string;
      break;
    case "bundesliga":
      links.push({
        title: t("Title_Gameschedule"),
        href: "/gameschedule/current/current/all",
      });
      links.push({ title: t("Title_Table"), href: "/table" });
      links.push({ title: t("Title_Rankings"), href: "/ranking/all/single" });
      links.push({ title: t("Title_Teams"), href: "/teams" });
      // links.push({ title: t("Title_Players"), href: "/players" });
      links.push({ title: t("Title_Spectators"), href: "/spectators" });
      links.push({ title: t("Title_Transfers"), href: "/transferlist" });
      links.push({ title: t("Title_TTBL_Finals"), href: "/finals" });
      title = t("Title_Bundesliga") as string;
      break;
    case "pokal":
      links.push({
        title: t("Title_Gameschedule"),
        href: "/gameschedule/current/current/all",
      });
      // links.push({ title: t("Title_Teams"), href: "/teams" });
      links.push({ title: t("Title_Pokal_Finals"), href: "/finals" });
      title = t("Title_Pokal") as string;
      break;
    case "about":
      links.push({ title: t("Title_TTBL_Sport_GmbH"), href: "/ttblsportgmbh" });
      links.push({ title: t("Title_Team"), href: "/team" });
      links.push({ title: t("Title_Structure"), href: "/structure" });
      links.push({ title: t("Title_Partners"), href: "/partners" });
      links.push({ title: t("Title_Downloads"), href: "/downloads" });
      links.push({ title: t("Title_Press"), href: "/press" });
      links.push({ title: t("Title_Jobs"), href: "/jobs" });
      // links.push({ title: t("Title_Rules"), href: "/rules" });

      title = t("Title_The_TTBL") as string;
      break;
    default:
      break;
  }

  const [firstLinks, restOfLinks] = splitArray(links, 7);
  const { scrolled } = useIsScrolled(175);
  let headlineHref = replace ? "/" : "/" + section + firstLinks[0].href;
  if (section === "news") {
    headlineHref = "/news/all/1";
  }

  return title ? (
    <div
      className={`max-lg:hidden bg-neutral-200 sticky top-0 z-10 ${
        scrolled && "shadow-md"
      }`}
    >
      <div className="subNav flex items-center text-lg py-4 container">
        <div
          className={`flex absolute items-center transition-all ease-in duration-300 overflow-hidden ${
            scrolled ? "opactiy-10 w-auto" : "opacity-0 w-0"
          }`}
        >
          <Link href="/" className="h-full w-auto">
            <Image
              width={50}
              height={50}
              className="rounded-full align-middle"
              src="/ttbl_subnav_icon.svg"
              alt="TTBL Home Button"
            />
          </Link>
          <div className="w-[2px] h-10 mx-4 rounded-full bg-gray-text" />
        </div>
        <div
          className={`flex items-center transition-transform ${
            scrolled ? "translate-x-[84px]" : ""
          }`}
        >
          <Link href={headlineHref} className="self-center">
            <Headline className="mr-8 !mb-0 lg:text-black">{title}</Headline>
          </Link>
          {firstLinks.map((link) => (
            <NavLink
              key={link.title}
              href={replace ? link.href : "/" + section + link.href}
              isSubnav
            >
              {link.title}
            </NavLink>
          ))}
          {restOfLinks.length > 0 && (
            <DropdownMenu>
              <DropdownMenuTrigger>
                <FiMoreHorizontal className="align-middle" />
              </DropdownMenuTrigger>
              <DropdownMenuContent className="p-4  border-neutral-300 rounded-md shadow-md">
                {restOfLinks.map((link) => (
                  <DropdownMenuItem key={link.href} className="subNav">
                    <NavLink
                      key={link.title}
                      href={replace ? link.href : "/" + section + link.href}
                      isSubnav
                    >
                      {link.title}
                    </NavLink>
                  </DropdownMenuItem>
                ))}
              </DropdownMenuContent>
            </DropdownMenu>
          )}
        </div>
      </div>
    </div>
  ) : null;
}

interface NavLinkProps extends WithClassName {
  href: string;
  prefix?: string;
  isSubnav?: boolean;
}
function NavLink({
  href,
  children,
  className,
  isSubnav = false,
}: NavLinkProps) {
  const { pathname, query, locale } = useRouter();

  let currentCategory = "/" + pathname.split("/")[1];
  let currentEndpoint = "/" + (pathname.split("/")[2] ?? "");
  const queryString = Object.values(query)[0];
  if (currentEndpoint.includes("[") && typeof queryString === "string") {
    currentEndpoint = "/" + queryString;
  }
  if (
    currentCategory === "/currentgameday" ||
    currentCategory === "/table" ||
    currentCategory === "/rankings" ||
    currentCategory === "/livestreams"
  ) {
    currentEndpoint = currentCategory;
  }
  if (
    currentCategory === "/currentgameday" ||
    currentCategory === "/table" ||
    currentCategory === "/rankings" ||
    currentCategory === "/livestreams"
  ) {
    currentCategory = "/";
  }
  const linkCategory = "/" + href.split("/")[1];
  const linkEndpoint = "/" + (href.split("/")[2] ?? "");
  const style = isSubnav
    ? currentEndpoint === linkEndpoint && currentEndpoint !== "/"
      ? "secondaryBtn active"
      : "secondaryBtn"
    : currentCategory === linkCategory
    ? "secondaryBtn active"
    : "secondaryBtn";
  return (
    <Link
      href={href}
      locale={locale}
      className={
        "flex flex-col h-fit after:mt-2 " + style + " " + (className ?? "")
      }
    >
      {children}
    </Link>
  );
}

interface BreadCrumbsProps {
  newsTags?: Tag[];
  article?: News;
  endpointSelectionString?: string;
}
function BreadCrumbs({
  newsTags,
  article,
  endpointSelectionString,
}: BreadCrumbsProps) {
  const { pathname, query } = useRouter();
  const { t } = useTranslation();

  let currentCategory = pathname.split("/")[1];
  let currentEndpoint = pathname.split("/")[2];
  let endpointSelection = endpointSelectionString;
  let categoryAddition = "";
  let endpointAddition = "";

  if (currentCategory === "news") {
    if (currentEndpoint === "[tag]" && typeof query.tag === "string") {
      currentEndpoint = query.tag;
      endpointAddition = "/1";
      categoryAddition = "/all/1";
      if (query.tag === "all") {
        currentEndpoint = "";
        endpointAddition = "";
      }
    }
    if (currentEndpoint === "post") {
      currentEndpoint = article?.primary_tag?.slug ?? "";
      endpointSelection = article?.title;
      endpointAddition = "/1";
      categoryAddition = "/all/1";
    }
  }

  let endpointName: string | undefined | null = getBreadCrumbEndPointName({
    category: currentCategory,
    endpoint: currentEndpoint,
    newsTags,
  });
  endpointName &&= t(endpointName);

  let breadCrumbCategoryName: string | undefined | null =
    getBreadCrumbCategoryName(currentCategory);
  breadCrumbCategoryName &&= t(breadCrumbCategoryName);

  if (currentCategory === "about") {
    categoryAddition = "/ttblsportgmbh";
  }
  if (currentCategory === "bundesliga" || currentCategory === "pokal") {
    categoryAddition = `/gameschedule/current/current/all`;
  }

  if (currentEndpoint === "gameschedule" || currentEndpoint === "gameday") {
    currentEndpoint = "gameschedule/current/current/all";
  }
  if (currentEndpoint === "players") {
    currentEndpoint = "ranking";
  }
  if (currentEndpoint === "ranking") {
    endpointAddition = "/all/single";
  }
  return (
    <div className="my-6 flex text-md text-gray-text container max-lg:hidden gap-2">
      <div>
        <Link href="/">Home</Link>
      </div>
      {currentCategory !== "/" && (
        <>
          <div>{">"}</div>
          <div>
            <Link href={`/${currentCategory}${categoryAddition}`}>
              {breadCrumbCategoryName}
            </Link>
          </div>
        </>
      )}
      {currentEndpoint && (
        <>
          <div>{">"}</div>
          <div>
            <Link
              href={`/${currentCategory}/${currentEndpoint}${endpointAddition}`}
            >
              {endpointName}
            </Link>
          </div>
        </>
      )}
      {endpointSelection && (
        <>
          <div>{">"}</div>
          <div>{endpointSelection}</div>
        </>
      )}
    </div>
  );
}

function getBreadCrumbCategoryName(category: string) {
  switch (category) {
    case "news":
      return "News";
    case "bundesliga":
      return "Bundesliga";
    case "pokal":
      return "Pokal";
    case "about":
      return "Title_The_TTBL";
    case "privacy":
      return "Title_Privacy_Policy";
    case "imprint":
      return "Title_Imprint";
    case "quiz":
      return "Quiz";
    case "contestconditions":
      return "Contest_Conditions";
    case "gameday":
      return "Form_Gameday";
    case "404":
      return "404";
    case "500":
      return "500";
    case "tickets":
      return "Title_Tickets";
    case "newsletter":
      return "Title_Newsletter";
    default:
      break;
  }
}

function getBreadCrumbEndPointName({
  category,
  endpoint,
  newsTags,
  article,
}: {
  category: string;
  endpoint: string;
  newsTags?: Tag[];
  article?: News;
}) {
  switch (category) {
    case "news":
      return endpoint === "post"
        ? article?.primary_tag.name ?? "TTBL.de"
        : newsTags?.find((n) => n.slug === endpoint)?.name ??
            endpoint.charAt(0).toUpperCase() + endpoint.slice(1);
    default:
      switch (endpoint) {
        case "gameschedule":
        case "gameday":
          return "Title_Gameschedule";
        case "ranking":
          return "Title_Rankings";
        case "spectators":
          return "Title_Spectators";
        case "teams":
          return "Nav_Teams";
        case "players":
          return "Nav_Players";
        case "transferlist":
          return "Title_Transfers";
        case "finals":
          return category === "bundesliga"
            ? "Title_TTBL_Finals"
            : "Title_Pokal_Finals";
        case "history":
          return "Title_TTBL_History";
        case "ttblsportgmbh":
          return "Title_TTBL_Sport_GmbH";
        case "team":
          return "Title_Team";
        case "structure":
          return "Title_Structure";
        case "socialproject":
          return "Title_Social_Project";
        case "partners":
          return "Title_Partners";
        case "rules":
          return "Title_Rules_And_Orders";
        case "jobs":
          return "Title_Jobs";
        case "table":
          return "Nav_Table";
        case "downloads":
          return "Title_Downloads";
        case "press":
          return "Title_Press";
        default:
          break;
      }
  }
}

interface PageHeaderProps extends React.PropsWithChildren {
  newsTags?: Tag[];
  article?: News;
  endpointSelectionString?: string;
}

function PageHeader({
  article,
  newsTags,
  endpointSelectionString,
  children,
}: PageHeaderProps) {
  const { pathname, query } = useRouter();
  const { t } = useTranslation();
  let currentCategory = pathname.split("/")[1];
  let currentEndpoint = pathname.split("/")[2];

  if (currentCategory === "news") {
    if (currentEndpoint === "[tag]" && typeof query.tag === "string") {
      currentEndpoint = query.tag;
      if (query.tag === "all") {
        currentEndpoint = "";
      }
    }
    if (currentEndpoint === "post") {
      currentEndpoint = article?.primary_tag?.slug ?? "";
    }
  }

  let endpointName: string | undefined | null = getBreadCrumbEndPointName({
    category: currentCategory,
    endpoint: currentEndpoint,
    newsTags,
  });
  endpointName &&= t(endpointName);
  let breadCrumbCategoryName: string | undefined | null =
    getBreadCrumbCategoryName(currentCategory);
  breadCrumbCategoryName &&= t(breadCrumbCategoryName);
  return (
    <div
      className={`container flex justify-between lg:mt-0 ${
        currentCategory === "" && "lg:mt-4"
      } ${currentEndpoint !== "gameday" ? "mt-4 mb-8" : "lg:mt-4 lg:mb-8"}`}
    >
      <Headline
        level={1}
        className={`!mb-0 ${
          currentEndpoint === "gameday" ? "max-lg:hidden" : ""
        }`}
      >
        {currentEndpoint === "gameday" ||
        currentEndpoint === "teams" ||
        currentEndpoint === "players"
          ? endpointSelectionString
            ? endpointSelectionString
            : endpointName
          : endpointName
          ? endpointName
          : breadCrumbCategoryName}
      </Headline>
      <div className="max-lg:hidden flex">
        {children}
        {/* <Share className="h-fit" /> */}
      </div>
    </div>
  );
}
