import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu";
import React, { ForwardedRef } from "react";
import { FiCheck } from "react-icons/fi";

export const DropdownMenu = DropdownMenuPrimitive.Root;
export const DropdownMenuTrigger = DropdownMenuPrimitive.Trigger;

const DropdownMenuContentComponent = (
  { children, ...props }: DropdownMenuPrimitive.DropdownMenuContentProps,
  forwardedRef: ForwardedRef<any>
) => {
  return (
    <DropdownMenuPrimitive.Portal>
      <DropdownMenuPrimitive.Content
        {...props}
        className={props.className + " bg-white z-20"}
        ref={forwardedRef}
      >
        {children}
        <DropdownMenuPrimitive.Arrow className="fill-white" />
      </DropdownMenuPrimitive.Content>
    </DropdownMenuPrimitive.Portal>
  );
};
export const DropdownMenuContent = React.forwardRef(
  DropdownMenuContentComponent
);
export const DropdownMenuLabel = DropdownMenuPrimitive.Label;
export const DropdownMenuItem = DropdownMenuPrimitive.Item;
export const DropdownMenuGroup = DropdownMenuPrimitive.Group;

export const DropdownMenuCheckboxItemComponent = (
  { children, ...props }: DropdownMenuPrimitive.DropdownMenuCheckboxItemProps,
  forwardedRef: ForwardedRef<any>
) => {
  return (
    <DropdownMenuPrimitive.CheckboxItem {...props} ref={forwardedRef}>
      {children}
      <DropdownMenuPrimitive.ItemIndicator>
        {props.checked === "indeterminate" && <hr />}
        {props.checked === true && <FiCheck />}
      </DropdownMenuPrimitive.ItemIndicator>
    </DropdownMenuPrimitive.CheckboxItem>
  );
};

export const DropdownMenuCheckboxItem = React.forwardRef(
  DropdownMenuCheckboxItemComponent
);

export const DropdownMenuRadioGroup = DropdownMenuPrimitive.RadioGroup;

export const DropdownMenuRadioItemComponent = (
  { children, ...props }: DropdownMenuPrimitive.DropdownMenuRadioItemProps,
  forwardedRef: ForwardedRef<any>
) => {
  return (
    <DropdownMenuPrimitive.RadioItem {...props} ref={forwardedRef}>
      {children}
      <DropdownMenuPrimitive.ItemIndicator>
        <FiCheck />
      </DropdownMenuPrimitive.ItemIndicator>
    </DropdownMenuPrimitive.RadioItem>
  );
};

export const DropdownMenuRadioItem = React.forwardRef(
  DropdownMenuRadioItemComponent
);

export const DropdownMenuSeparator = DropdownMenuPrimitive.Separator;
