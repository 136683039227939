import Image from "next/image";
import Link from "next/link";
import { useTranslation } from "next-i18next";

interface DynLinkProps {
  href: string;
}
export function DynLink({ href }: DynLinkProps) {
  const { t } = useTranslation();

  return (
    <Link href={href} className="btn btnExternal btnDYN btnNav" target="_blank">
      <div className="align-bottom">
        <Image
          src="/logos/Dyn_Logo.png"
          alt="Logo DYN"
          className="mr-4"
          width={173}
          height={20}
        />
      </div>
      <div className="align-bottom">{t("Nav_TTBL_Livestreams")}</div>
    </Link>
  );
}
